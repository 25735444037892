@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500);

.body404 {
  background-color: #007aff;
  color: #fff;
  font-size: 100%;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
}

.button404 {
  font-weight: 300;
  color: #fff;
  font-size: 1.2em;
  text-decoration: none;
  border: 1px solid #efefef;
  padding: 0.5em;
  border-radius: 3px;
  float: left;
  margin: 6em 0 0 -155px;
  left: 50%;
  position: relative;
  transition: all 0.3s linear;
}

.button404:hover {
  background-color: #007aff;
  color: #fff;
}

.msg404 {
  font-size: 2em;
  text-align: center;
  font-weight: 100;
}

.h1404 {
  text-align: center;
  font-size: 15em;
  font-weight: 100;
  text-shadow: #0062cc 1px 1px, #0062cc 2px 2px, #0062cc 3px 3px,
    #0062cd 4px 4px, #0062cd 5px 5px, #0062cd 6px 6px, #0062cd 7px 7px,
    #0062ce 8px 8px, #0063ce 9px 9px, #0063ce 10px 10px, #0063ce 11px 11px,
    #0063cf 12px 12px, #0063cf 13px 13px, #0063cf 14px 14px, #0063cf 15px 15px,
    #0063d0 16px 16px, #0064d0 17px 17px, #0064d0 18px 18px, #0064d0 19px 19px,
    #0064d1 20px 20px, #0064d1 21px 21px, #0064d1 22px 22px, #0064d1 23px 23px,
    #0064d2 24px 24px, #0065d2 25px 25px, #0065d2 26px 26px, #0065d2 27px 27px,
    #0065d3 28px 28px, #0065d3 29px 29px, #0065d3 30px 30px, #0065d3 31px 31px,
    #0065d4 32px 32px, #0065d4 33px 33px, #0066d4 34px 34px, #0066d4 35px 35px,
    #0066d5 36px 36px, #0066d5 37px 37px, #0066d5 38px 38px, #0066d5 39px 39px,
    #0066d6 40px 40px, #0066d6 41px 41px, #0067d6 42px 42px, #0067d6 43px 43px,
    #0067d7 44px 44px, #0067d7 45px 45px, #0067d7 46px 46px, #0067d7 47px 47px,
    #0067d8 48px 48px, #0067d8 49px 49px, #0068d8 50px 50px, #0068d9 51px 51px,
    #0068d9 52px 52px, #0068d9 53px 53px, #0068d9 54px 54px, #0068da 55px 55px,
    #0068da 56px 56px, #0068da 57px 57px, #0068da 58px 58px, #0069db 59px 59px,
    #0069db 60px 60px, #0069db 61px 61px, #0069db 62px 62px, #0069dc 63px 63px,
    #0069dc 64px 64px, #0069dc 65px 65px, #0069dc 66px 66px, #006add 67px 67px,
    #006add 68px 68px, #006add 69px 69px, #006add 70px 70px, #006ade 71px 71px,
    #006ade 72px 72px, #006ade 73px 73px, #006ade 74px 74px, #006bdf 75px 75px,
    #006bdf 76px 76px, #006bdf 77px 77px, #006bdf 78px 78px, #006be0 79px 79px,
    #006be0 80px 80px, #006be0 81px 81px, #006be0 82px 82px, #006be1 83px 83px,
    #006ce1 84px 84px, #006ce1 85px 85px, #006ce1 86px 86px, #006ce2 87px 87px,
    #006ce2 88px 88px, #006ce2 89px 89px, #006ce2 90px 90px, #006ce3 91px 91px,
    #006de3 92px 92px, #006de3 93px 93px, #006de3 94px 94px, #006de4 95px 95px,
    #006de4 96px 96px, #006de4 97px 97px, #006de4 98px 98px, #006de5 99px 99px,
    #006ee5 100px 100px, #006ee5 101px 101px, #006ee6 102px 102px,
    #006ee6 103px 103px, #006ee6 104px 104px, #006ee6 105px 105px,
    #006ee7 106px 106px, #006ee7 107px 107px, #006ee7 108px 108px,
    #006fe7 109px 109px, #006fe8 110px 110px, #006fe8 111px 111px,
    #006fe8 112px 112px, #006fe8 113px 113px, #006fe9 114px 114px,
    #006fe9 115px 115px, #006fe9 116px 116px, #0070e9 117px 117px,
    #0070ea 118px 118px, #0070ea 119px 119px, #0070ea 120px 120px,
    #0070ea 121px 121px, #0070eb 122px 122px, #0070eb 123px 123px,
    #0070eb 124px 124px, #0071eb 125px 125px, #0071ec 126px 126px,
    #0071ec 127px 127px, #0071ec 128px 128px, #0071ec 129px 129px,
    #0071ed 130px 130px, #0071ed 131px 131px, #0071ed 132px 132px,
    #0071ed 133px 133px, #0072ee 134px 134px, #0072ee 135px 135px,
    #0072ee 136px 136px, #0072ee 137px 137px, #0072ef 138px 138px,
    #0072ef 139px 139px, #0072ef 140px 140px, #0072ef 141px 141px,
    #0073f0 142px 142px, #0073f0 143px 143px, #0073f0 144px 144px,
    #0073f0 145px 145px, #0073f1 146px 146px, #0073f1 147px 147px,
    #0073f1 148px 148px, #0073f1 149px 149px, #0074f2 150px 150px,
    #0074f2 151px 151px, #0074f2 152px 152px, #0074f3 153px 153px,
    #0074f3 154px 154px, #0074f3 155px 155px, #0074f3 156px 156px,
    #0074f4 157px 157px, #0074f4 158px 158px, #0075f4 159px 159px,
    #0075f4 160px 160px, #0075f5 161px 161px, #0075f5 162px 162px,
    #0075f5 163px 163px, #0075f5 164px 164px, #0075f6 165px 165px,
    #0075f6 166px 166px, #0076f6 167px 167px, #0076f6 168px 168px,
    #0076f7 169px 169px, #0076f7 170px 170px, #0076f7 171px 171px,
    #0076f7 172px 172px, #0076f8 173px 173px, #0076f8 174px 174px,
    #0077f8 175px 175px, #0077f8 176px 176px, #0077f9 177px 177px,
    #0077f9 178px 178px, #0077f9 179px 179px, #0077f9 180px 180px,
    #0077fa 181px 181px, #0077fa 182px 182px, #0077fa 183px 183px,
    #0078fa 184px 184px, #0078fb 185px 185px, #0078fb 186px 186px,
    #0078fb 187px 187px, #0078fb 188px 188px, #0078fc 189px 189px,
    #0078fc 190px 190px, #0078fc 191px 191px, #0079fc 192px 192px,
    #0079fd 193px 193px, #0079fd 194px 194px, #0079fd 195px 195px,
    #0079fd 196px 196px, #0079fe 197px 197px, #0079fe 198px 198px,
    #0079fe 199px 199px, #007aff 200px 200px;
}
