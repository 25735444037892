body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: "Product Sans";
}

@font-face {
  font-family: "Grifter";
  src: url("./fonts/grifterbold.otf") format("opentype");
}

@font-face {
  font-family: "Product Sans";
  src: url("./fonts/productsans.ttf") format("truetype");
}

* {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
}

/* Disable tap highlight for specific elements */
a,
button {
  -webkit-tap-highlight-color: transparent;
}

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none; /* Chrome, Safari, Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.page {
  width: 100vw;
  height: 100vh;
}

.logo-label {
  color: black;
  font-family: "Grifter";
}

.cont-google img {
  width: 13em;
}
.cont-google {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cont-google-btn {
  left: 50%;
  transform: translateX(-50%);
}

.selection * {
  -webkit-user-select: text; /* Chrome, Safari, Opera */
  -khtml-user-select: text; /* Konqueror */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
  user-select: text; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
